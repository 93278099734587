import React from "react";

const About = () => {
  return (
    <section className="section about-section">
      <h1 className="section-title">About Us</h1>
      <p>
        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odio, aliquid
        optio, in velit minus tenetur aperiam vel nihil voluptas repellendus
        nemo doloribus perferendis distinctio ducimus eos recusandae dolores
        blanditiis sed, nostrum tempora placeat at unde omnis voluptate?
        Consequuntur, optio provident.
      </p>
    </section>
  );
};

export default About;
